const Env = {db:{}}
window.Env = Env

import $ from 'jquery'
import _ from 'lodash'

import 'jquery.fancytree/dist/skin-lion/ui.fancytree.css';  // CSS or LESS

import {createTree} from 'jquery.fancytree';

import 'jquery.fancytree/dist/modules/jquery.fancytree.edit';
import 'jquery.fancytree/dist/modules/jquery.fancytree.filter';


import {fabric} from 'fabric'
import * as dat from 'dat.gui'
import FuzzySearch from 'fuzzy-search';
import { Draggable } from '@shopify/draggable';
import {fromEvent, pipe} from 'rxjs';
import { map, filter, switchMap,take, takeUntil, skipUntil, mergeMap} from 'rxjs/operators';
import ee from 'event-emitter'

import * as Palace from './models/palace'

import * as quickpreview from './tools/quickpreview'

require('dotenv').load()

quickpreview.attach()

// golden-layout need to have jquery in the window context.
// TODO: solve this issue better
window.$ = $
window.jQuery = $

import './styles/base.styl'

// import React from "react"
// import ReactDOM from "react-dom"
// window.React = React
// window.ReactDOM = ReactDOM

import hotkeys from 'hotkeys-js';
hotkeys()


const baseUrl = process.env.BASE_URL



// ************
// palace functions
// ************

// const getPalace = (id) => {
//   return Env.db.palaceList.find( (palace) => palace.id ==  id)
// }
// const getCurrentPalace = () => {
//   const palace = getPalace(Env.db.currentPalaceID)
//   if (palace) {
//     return palace
//   } else if (Env.db.palaceList.length > 1) {
//     return Env.db.palaceList[0]
//   } else {
//     return null
//   }
// }





let currentCanvasTarget
const checkCanvasForPreview = (shift) => {
  if (currentCanvasTarget && currentCanvasTarget.src && shift) {
    quickpreview.setObject(currentCanvasTarget)
  } else {
    quickpreview.clear()
  }
}
$(document).on('keydown', (e)=>{
  console.log("checking", e.originalEvent.code === 'ShiftLeft' || e.originalEvent.code === 'ShiftRight')
  if (e.originalEvent.code === 'ShiftLeft' || e.originalEvent.code === 'ShiftRight') {
    checkCanvasForPreview(true)
  }
})
$(document).on('keyup', (e)=>{
  if (e.originalEvent.code === 'ShiftLeft' || e.originalEvent.code === 'ShiftRight') {
    quickpreview.clear()
  }
})
const loadCanva = () => {

  const height = window.innerHeight
  const width = window.innerWidth
  const canvasElement = document.createElement('canvas')
  $('.app-wrapper').html(canvasElement)
  const canvas = new fabric.Canvas(canvasElement);
  // $(document.body).html(canvasElement)
  canvas.on('mouse:over', function(e) {
    // debugger
    currentCanvasTarget = e.target
    checkCanvasForPreview(hotkeys.isPressed('shift'))
    canvas.renderAll();
  });

  canvas.on('mouse:out', function(e) {
    currentCanvasTarget = undefined
    quickpreview.clear()
  })

  const render = () => {
    canvas.setHeight(height);
    canvas.setWidth(width);
    canvas.renderAll()
  }
  render()
  // TODO: resize canva onresize window


  const startZoomOffset = {}
  let startZoomOn = false
  let startZoom = 1

  return {
    canvasElement: canvasElement,
    canvas: canvas,
  }
}
const {canvas} = loadCanva()
Env.currentFabric = canvas

const renderPalace = (palaceName) => {
  // should fetch storage.inspiration.goa.io/p/{palaceName}
  // but now for testing purpose, get all the from storage.inspiration.goa.io
  fetch(`${baseUrl}/public/palaces/${palaceName}.json?${Date.now()}`, {cache: "no-store"}).then((res)=>{
    return res.json()
  }).then((palace)=>{
    // const palace = db.palaceList.find((palace)=>palace.name === palaceName)
    // console.log("palacel, palace", palace)
    console.log("load canvas")
    Palace.load(canvas, palace, ()=>{
      Palace.deactivateSelection()
    })
    canvas.selection = false
    // console.log("canvas")
    canvas.getObjects().forEach(x=>x.set('selectable', false))
    // loadPalace(canvas, palace)

  })
}
const url = new URL(location);
const palace = url.searchParams.get("palace")
renderPalace(palace)

// // ************
// // quick preview
// // ************
// let $quickPreview
// $quickPreview = $(`<div class='quickpreview'></div>`)
// $quickPreview.css({
//   'position': 'absolute',
//   'top': 1,
//   'display': 'flex',
//   'align-items': 'center',
//   'justify-content': 'center',
//   'height': '100vh',
//   'width': '100vw',
//   'z-index': '9999999',
//   'pointer-events': 'none',
// })
// const $body = $(document.body)
// $body.append($quickPreview)
// $body.css({
//   'overflow': 'hidden',
//   'background': '#faf8f5'
// })


// const setQuickPreview = (src) => {
//   console.log('setQuickPreview', src)
//   const $img = $(`<img src='${src}' />`)
//   $img.css({
//     'max-height': '100%',
//     'max-width': '100%',
//     'border-radius': '5px',
//   })
//   $('.lm_goldenlayout').css({'-webkit-filter': 'blur(10px)'})
//   $quickPreview.html($img)
// }
// const clearQuickPreview = () => {
//   $('.lm_goldenlayout').css({'-webkit-filter': ''})
//   $quickPreview.html('')
// }