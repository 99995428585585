import hotkeys from 'hotkeys-js';
import _ from 'lodash'
import { eventNames } from 'cluster';

//TODO: place this somewhere else, check for duplicates
const fabricCustomAttribute = ['id', 'sid', 'typeSID','filePath', 'palaceID']
const baseUrl = process.env.BASE_URL

Env = window.Env

export const getNewPalaceId = ()=>{
  if (_.isUndefined(Env.db.lastPalaceID)) {
    Env.db.lastPalaceID = 0
  }
  return Env.db.lastPalaceID++
}

export const getAll = () => Env.db.palaceList
export const get = (id) => _.find(getAll(),  (palace) => palace.id ==  id)
export const create = (obj={}) => {
  const palace = {
    sequence: [], ...obj, id: getNewPalaceId()
  }
  Env.db.palaceList.push(palace)
  return palace
}

export const transformImagePath = (imageObjects)=> {
  imageObjects.forEach((imageObject)=>{
    const src = baseUrl + "/public/img/original/" + imageObject.filePath
    imageObject.src = src
  })
}

export const load = (canvas, palace, cb=(()=>{})) => {
  canvas.clear()
  if (palace && palace.fabricObject) {
    transformImagePath(palace.fabricObject.objects)
    console.log("cb", cb)
    canvas.loadFromJSON(palace.fabricObject, cb)
    if (!palace.viewportTransform) {
      palace.viewportTransform = [1, 0, 0, 1, 0, 0]
    }
    canvas.setViewportTransform(palace.viewportTransform)
  } else {
    console.error("canvas is empty")
  }
  Env.db.currentPalaceID = palace.id
}

// const loadPalace = (canvas, palace) => {
//   canvas.clear()
//   if (palace && palace.fabricObject) {
//     const transformImagePath = (imageObjects)=> {
//       imageObjects.forEach((imageObject)=>{
//         imageObject.src = "http://localhost:3050/public/img/original/" + imageObject.filePath
//         console.log("src", imageObject)
//       })
//     }
//     transformImagePath(palace.fabricObject.objects)
//     canvas.loadFromJSON(palace.fabricObject)
//     if (!palace.viewportTransform) {
//       palace.viewportTransform = [1, 0, 0, 1, 0, 0]
//     }
//     canvas.setViewportTransform(palace.viewportTransform)
//   } else {
//     addSimpleObjects(canvas)
//   }
//   Env.db.currentPalaceID = palace.id
// }


export const loadOrCreate = (canvas, palace) => {
  if (palace) {
    load(canvas, palace)
  } else {
    palace = create({name: 'new palace'})
    load(canvas, palace)
  }
}
export const replaceCurrent = (palace) => {
  saveCurrent()
  load(Env.currentFabric, palace)
}


export const getCurrent = () => {
  const palace = get(Env.db.currentPalaceID)
  if (palace) {
    return palace
  } else if (Env.db.palaceList.length > 1) {
    return Env.db.palaceList[0]
  } else {
    return null
  }
}
export const saveCurrent = ()=> {
  const palace = getCurrent()
  if (palace) {
    const savedFabric = Env.currentFabric.toJSON(fabricCustomAttribute)
    palace.fabricObject = savedFabric
    palace.viewportTransform = Env.currentFabric.viewportTransform
  } else {
    console.warn('No current palace')
  }
}

export const getSeed = ()=> _.find(getAll(), x=>x.name == "seed-palace")


export const activateSelection = ()=>{
  Env.currentFabric.selection = true
  Env.currentFabric.getObjects().forEach((object)=>{
    object.set('selectable', true)
  })
}
export const deactivateSelection = ()=>{
  console.log("desactivateSelection")
  Env.currentFabric.selection = false
  Env.currentFabric.getObjects().forEach((object)=>{
    object.set('selectable', false)
  })
}
export const checkSelection = () => {
  if (hotkeys.isPressed('space') || hotkeys.isPressed('alt')){
    deactivateSelection()
  } else {
    activateSelection()
  }
}