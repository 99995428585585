import $ from 'jquery'

// ************
// quick preview
// ************
let $quickPreview


export const attach = () => {
  $quickPreview = $(`<div class='goa-quickpreview'></div>`)
  $quickPreview.css({
    'position': 'absolute',
    'display': 'flex',
    'top': '0',
    'align-items': 'center',
    'justify-content': 'center',
    'height': '100vh',
    'width': '100vw',
    'z-index': '9999999',
    'pointer-events': 'none',
  })
  $(document.body).append($quickPreview)
}

let t
let $el = $(`<div />`)

export const setObject = (obj) => {
  // clearTimeout(t)
  if (obj.typeSID === "subject") {
    $el = $(`
      <div>
        ${obj.sid}
      </div>`)
  } else if (obj.src) {
    $el = $(`<img src='${obj.src}' />`)
    $el.css({
      'max-height': '100%',
      'max-width': '100%',
      'border-radius': '5px',
      'box-shadow': '3px 3px 50px rgba(0, 0, 0, .3)'
    })
    //TODO: set the classlink somewhere else
    // $('.app-wrapper-overlay').fadeIn(100)
    $('.app-wrapper-overlay').show()
    $('.app-wrapper').css({
      '-webkit-filter': 'blur(10px)'
    })
    $quickPreview.html($el)
  }
}

export const clear = () => {
  //TODO: set the classlink somewhere else
  // t = setTimeout(()=>{
    // $el.fadeOut(100)
    // $('.app-wrapper-overlay').fadeOut(100, ()=>{
    $('.app-wrapper-overlay').hide()
      $('.app-wrapper').css({
        '-webkit-filter': ''
      })
      $quickPreview.html('')
    // })
  // }, 100)
}

